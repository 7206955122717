<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-50 ml-50">
              포인트 제도 관리
            </p>
            <v-select
              v-model="query.year"
              :options="codes.years"
              :reduce="option => option.code"
              class="el-def"
              @input="fetchRecords()"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-table-simple>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>1월</b-th>
                  <b-th>2월</b-th>
                  <b-th>3월</b-th>
                  <b-th>4월</b-th>
                  <b-th>5월</b-th>
                  <b-th>6월</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td
                    v-for="item in summaryTop"
                    :key="item.month"
                  >
                    <b-button
                      :variant="item.isReg ? 'outline-secondary' : 'outline-primary'"
                      :disabled="item.expire"
                      @click.prevent="openEditModal(item.year, item.month)"
                    >
                      <feather-icon
                        :icon="item.isReg ? 'CheckSquareIcon' : 'PlusSquareIcon'"
                        class="mr-50"
                      />
                      <span>{{ item.isReg ? '등록완료' : '등록하기' }}</span>
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>7월</b-th>
                  <b-th>8월</b-th>
                  <b-th>9월</b-th>
                  <b-th>10월</b-th>
                  <b-th>11월</b-th>
                  <b-th>12월</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td
                    v-for="item in summaryBottom"
                    :key="item.month"
                  >
                    <b-button
                      :variant="item.isReg ? 'outline-secondary' : 'outline-primary'"
                      :disabled="item.expire"
                      @click.prevent="openEditModal(item.year, item.month)"
                    >
                      <feather-icon
                        :icon="item.isReg ? 'CheckSquareIcon' : 'PlusSquareIcon'"
                        class="mr-50"
                      />
                      <span>{{ item.isReg ? '등록완료' : '등록하기' }}</span>
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              등록내역 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="month"
        :items="records"
        :fields="tableColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(yearMonth)="data">
          <span class="text-nowrap">
            {{ data.item.year + '.' + (data.item.month >= 10 ? data.item.month : `0${data.item.month}`) }}
          </span>
        </template>
        <template #cell(drugInfo)="data">
          <span class="text-nowrap">
            {{ data.item.drugInfoGoal ? `${data.item.drugInfoGoal}회 / ${data.item.drugInfoPoint}p` : '' }}
          </span>
        </template>
        <template #cell(medicalTrend)="data">
          <span class="text-nowrap">
            {{ data.item.medicalTrendGoal ? `${data.item.medicalTrendGoal}회 / ${data.item.medicalTrendPoint}p` : '' }}
          </span>
        </template>
        <template #cell(webSeminar)="data">
          <span class="text-nowrap">
            {{ data.item.webSeminarGoal ? `${data.item.webSeminarGoal}회 / ${data.item.webSeminarPoint}p` : '' }}
          </span>
        </template>
        <template #cell(weeklyChoice)="data">
          <span class="text-nowrap">
            {{ data.item.weeklyChoiceGoal ? `${data.item.weeklyChoiceGoal}회 / ${data.item.weeklyChoicePoint}p` : '' }}
          </span>
        </template>
        <template #cell(isSaveImage)="data">
          <span>
            {{ data.value ? 'Y' : 'N' }}
          </span>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="recordSub.showEditModal"
      cancel-variant="outline-secondary"
      centered
      ok-title="저장"
      cancel-title="닫기"
      size="lg"
      title="포인트 제도 등록"
      @ok.prevent="saveRecord()"
    >
      <validation-observer ref="pointSystemRules">
        <b-row class="mt-1">
          <b-col
            md="3"
            class="pt-50 text-center"
          >
            <h5>적용기간</h5>
          </b-col>
          <b-col
            md="4"
            class="pt-50"
          >
            <h5>{{ record.year + '-' + (record.month >= 10 ? record.month : `0${record.month}`) }}</h5>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="3"
            class="pt-50 text-center"
          >
            <h5>안내 이미지 업로드</h5>
          </b-col>
          <b-col md="4">
            <b-form-file
              v-model="recordSub.attachFile"
              accept="image/*"
              @input="uploadInformationImage"
            />
          </b-col>
          <b-col
            md="3"
            class="pt-50 text-center"
          >
            <h5>{{ recordSub.attachFileName }}</h5>
          </b-col>
          <b-col
            v-show="recordSub.showPreviewButton"
            md="2"
          >
            <b-button
              id="main-image-preview"
              @click.prevent="previewInformationImage()"
            >
              <span>미리보기</span>
            </b-button>
            <b-modal v-model="recordSub.showPreviewModal">
              <b-img
                :src="recordSub.attachFileSrc"
                fluid
              />
            </b-modal>
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) in record.categories"
          :key="item.category"
          class="mt-1"
        >
          <b-col
            md="3"
            class="pt-50 text-center"
          >
            <h5>{{ item.categoryName }}</h5>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              :rules="{ requiredIf : (record.categories[index].point != null && record.categories[index].point != '') }"
            >
              <b-form-input
                v-model="record.categories[index].goal"
                placeholder="횟수"
                type="number"
                min="1"
                oninput="validity.valid||(value='');"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              :rules="{ requiredIf : (record.categories[index].goal != null && record.categories[index].goal != '') }"
            >
              <b-form-input
                v-model="record.categories[index].point"
                placeholder="지급 포인트"
                type="number"
                min="1"
                oninput="validity.valid||(value='');"
                :disabled="(record.categories[index].goal == null || record.categories[index].goal == '')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BFormFile, BImg,
} from 'bootstrap-vue'
import {
  ref, onMounted, computed, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, requiredIf } from '@validations'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
    vSelect,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormFile,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    onMounted(() => {
      fetchRecords()
    })
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const thisYear = dayjs().year()
    const query = ref({
      year: thisYear,
    })

    const codes = ref({
      years: [
        { label: "2023년", code: 2023 },
        { label: "2024년", code: 2024 },
        { label: "2025년", code: 2025 },
        { label: "2026년", code: 2026 },
        { label: "2027년", code: 2027 },
        { label: "2028년", code: 2028 },
      ],
    })

    const recordInitState = {
      year: null,
      month: null,
      informationImageId: null,
      categories: [],
    }
    const recordSubInitState = {
      showEditModal: false,
      showPreviewModal: false,
      showPreviewButton: false,
      attachFile: null,
      attachFileSrc: null,
      attachFileName: null,
    }
    const record = ref({ ...recordInitState })
    const recordSub = ref({ ...recordSubInitState })
    const resetRecord = () => {
      Object.assign(record.value, recordInitState)
      Object.assign(recordSub.value, recordSubInitState)
    }

    const records = ref([])
    const summary = ref([])
    const summaryTop = computed(() => summary.value.filter(s => s.month <= 6).sort(s => s.month))
    const summaryBottom = computed(() => summary.value.filter(s => s.month > 6).sort(s => s.month))
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'yearMonth', label: '적용일시' },
      { key: 'drugInfo', label: '약품정보' },
      { key: 'medicalTrend', label: '메디컬트렌드' },
      { key: 'webSeminar', label: '웹심포지엄' },
      { key: 'weeklyChoice', label: 'WeeklyChoice' },
      { key: 'isSaveImage', label: '파일 등록 여부' },
      { key: 'createDate', label: '등록일' },
    ]

    const fetchRecords = () => {
      const searchYear = query.value.year
      if (!searchYear) {
        pushToast('warning', '조회 기준연도가 설정되지 않았습니다.')
        return
      }

      axios.get(`/fa/pointsystem/list/${searchYear}`)
        .then(rs => {
          const list = []
          for (let i = 1; i <= 12; i += 1) {
            const temp = rs.data.find(d => d.month === i)
            const item = {
              year: searchYear,
              month: i,
              expire: temp ? temp.expire : calculateExpire(searchYear, i),
              isReg: !!temp,
            }
            list.push(item)
          }

          summary.value = list
          totalRecords.value = rs.data.length
          records.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const calculateExpire = (year, month) => dayjs().isAfter(dayjs().year(year).month(month).date(1))

    const openEditModal = (year, month) => {
      resetRecord()

      axios.get(`/fa/pointsystem/form/${year}/${month}`)
        .then(rs => {
          record.value = rs.data

          if (rs.data.informationImageId) {
            fetchInformationImage(rs.data.informationImageId)
          }

          recordSub.value.showEditModal = true
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const saveRecord = () => {
      const validPromise = refs.pointSystemRules.validate()
      validPromise.then(valid => {
        if (!valid) {
          pushToast('danger', '입력하지 않았거나, 유효하지 않은 데이터가 있습니다.')
          return
        }

        const { categories } = record.value
        const isSet = categories.some(x => x.point || x.goal)

        if (isSet === false) {
          pushToast('danger', '입력된 목표 건 수가 없습니다.')
          return
        }

        axios.put('/fa/pointsystem/save', record.value)
          .then(rs => {
            if (rs.data.year && rs.data.month) {
              pushToast('success', '저장하였습니다.')
              recordSub.value.showEditModal = false
            } else {
              pushToast('danger', '데이터 저장에 실패하였습니다.')
            }
            fetchRecords()
          })
          .catch(err => {
            pushToast('danger', errorFormatter(err, '데이터 저장 중 오류가 발생하였습니다.'))
          })
      })
    }

    const uploadInformationImage = file => {
      if (!file) {
        pushToast('warning', '이미지가 선택되지 않았습니다.')
        recordSub.value.attachFile = null
        return
      }

      const formData = new FormData()
      formData.append('containerName', 'pointsystem')
      formData.append('file', file)

      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            record.value.informationImageId = rs.data.id
            fetchInformationImage(rs.data.id)
          } else {
            pushToast('danger', '파일 업로드에 실패하였습니다.')
          }
        })
        .catch(() => {
          pushToast('danger', '파일 업로드 중 오류가 발생하였습니다.')
        })
    }

    const fetchInformationImage = id => {
      if (!id) {
        pushToast('warning', '조회할 파일이 없습니다.')
        return
      }

      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            recordSub.value.attachFileName = rs.data.fileName
            recordSub.value.attachFileSrc = rs.data.filePath
            recordSub.value.showPreviewButton = true
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
          pushToast('danger', '파일 조회 중 오류가 발생하였습니다.')
        })
    }

    const previewInformationImage = () => {
      if (!recordSub.value.attachFileSrc) {
        pushToast('warning', '업로드된 이미지가 없습니다.')
        return
      }

      recordSub.value.showPreviewModal = true
    }

    return {
      query,
      codes,
      fetchRecords,
      records,
      summaryTop,
      summaryBottom,
      totalRecords,
      tableColumns,
      openEditModal,
      record,
      recordSub,
      uploadInformationImage,
      previewInformationImage,
      saveRecord,
      required,
      requiredIf,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
th, td {
    text-align: center;
}
</style>
